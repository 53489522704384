import React from "react";
import AppLayout from "../components/app-layout";
import { Button, Box, Heading } from "grommet";
import { Android, Apple } from "grommet-icons";

export default () => (
  <AppLayout>
    <Box
      pad={{ horizontal: "large", vertical: "large" }}
      margin={{ horizontal: "small" }}
      align="center"
    >
      <Heading level={1} color="accent-2" textAlign="center">
        Hi there!
      </Heading>
      <Heading
        level={3}
        color="accent-2"
        textAlign="center"
        margin={{ bottom: "small" }}
      >
        We're excited to show you this report, but it’s only available in the
        Gini Health App.
        <br />
        <br />
        Go ahead and install it. It’s free.
      </Heading>
      <Box width="small">
        <Button
          margin={{ vertical: "medium" }}
          href="https://apps.apple.com/app/gini-smart-nutrition-tracker/id1353057727"
          style={{ textAlign: "center" }}
          fill="horizontal"
          label={"iOS"}
          color="accent-1"
          icon={<Android />}
          primary
        />
        <Button
          href="https://play.google.com/store/apps/details?id=com.healthbit.gini"
          style={{ textAlign: "center" }}
          fill="horizontal"
          label={"Android"}
          color="accent-1"
          icon={<Apple />}
          primary
        />
      </Box>
    </Box>
  </AppLayout>
);
